import { getDatabase, ref, get } from 'firebase/database';

const generateTextToImage = async (prompt) => {
  try {
    const database = getDatabase();
    const apiKeyRef = ref(database, 'diffusion');
    const snapshot = await get(apiKeyRef);
    if (snapshot.exists()) {
      const STABILITY_API_KEY = snapshot.val();
      if (!STABILITY_API_KEY) {
        console.error('STABILITY_API_KEY environment variable is not set');
        return null;
      }

      const BASE_URL = 'https://api.stability.ai';
      const apiUrl = `${BASE_URL}/v1/generation/stable-diffusion-xl-1024-v1-0/text-to-image`;

      const requestData = {
        text_prompts: [{ text: prompt }],
        cfg_scale: 7,
        height: 1024,
        width: 1024,
        samples: 1,
        steps: 30,
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'image/png',
          Authorization: `Bearer ${STABILITY_API_KEY}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      return blob;
    } else {
      console.error('API Key not found in Firebase Realtime Database');
      return null;
    }
  } catch (error) {
    console.error('Error:', error.message);
    return null;
  }
};

export default generateTextToImage;
