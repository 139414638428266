import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import ImgProcessing from '../processing/img';

const GeneratorCropper = ({ data, imgsrc, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropCompleteHandler = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    try {
      const croppedImageBlob = await getCroppedImg(imgsrc, croppedAreaPixels);
      const croppedImageFile = new File([croppedImageBlob], 'cropped-image.jpg', { type: 'image/jpeg' });
      
      const { jpgFile, faceDetected, faceLandmarks } = await ImgProcessing(data).run(croppedImageFile);

      const uploadFile = jpgFile;

      onCropComplete(croppedAreaPixels, faceDetected, faceLandmarks, imgsrc, uploadFile);
    } catch (error) {
      console.error('Error during cropping or face detection:', error);
    }
  }, [imgsrc, data, onCropComplete]);

  const onZoomChange = useCallback((newZoom) => {
    data.generatorEditorData.cropperData.zoom = newZoom;
    setZoom(newZoom);
  }, [data]);

  const onCropChange = useCallback((newCrop) => {
    data.generatorEditorData.cropperData.editorWindow.x = newCrop.x;
    data.generatorEditorData.cropperData.editorWindow.y = newCrop.y;
    setCrop(newCrop);
  }, [data]);

  const createImage = (url) => new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas is empty or failed to create a Blob'));
        }
      }, 'image/jpeg');
    });
  };

  return (
    <div>
      <Cropper
        aspect={1 / 1}
        crop={crop}
        cropShape="round"
        image={imgsrc}
        maxZoom={10}
        onCropChange={onCropChange}
        onCropComplete={onCropCompleteHandler}
        onZoomChange={onZoomChange}
        showGrid={true}
        zoom={zoom}
      />
    </div>
  );
};

export default GeneratorCropper;
