class VitruvianBody {
    constructor(data){
        this.data=data;
        this.child=null
    }

    run(){
        this.child=this.data.mediaprocessing.child;
        this.child.material.color.set(0xffffff);
        this.child.material.map = this.data.mediaprocessing.textureColorAverage;
        this.child.material.needsUpdate = true;
    }
};

export default VitruvianBody;
