import './index.css';
import config from './aws-exports';
import VitruvianHome from './home/home';
import Changelog from './info/changelog';
import Bugs from './info/bugs';
import React from 'react';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyCAwULsEgV1V7eHgwR9WllZ6AK4SjUInxQ",
  authDomain: "phi-vitruvian.firebaseapp.com",
  projectId: "phi-vitruvian",
  storageBucket: "phi-vitruvian.appspot.com",
  messagingSenderId: "360940067521",
  appId: "1:360940067521:web:c89b7b57f78be77bb8d240",
  measurementId: "G-TGKBWRWWDF"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Amplify.configure(config);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<VitruvianHome />} />
        <Route path="/bugs" element={<Bugs/>} />
        <Route path="/changelog" element={<Changelog/>} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
