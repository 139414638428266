import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const SceneWrapper = ({ setScene, sceneRef }) => {
  const { scene } = useThree();

  useEffect(() => {
    if (scene) {
      sceneRef.current = scene;
      setScene(scene);
    }
  }, [scene, setScene, sceneRef]);

  return null;
};

export default SceneWrapper;
