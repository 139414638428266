import { FaceLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';
export default async function runLandmarksInit(file) {
    try {
      const vision = await FilesetResolver.forVisionTasks('https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm');
      const faceLandmarker = await FaceLandmarker.createFromModelPath(
        vision,
        'https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task'
      );
  
      const image = new Image();
      image.src = URL.createObjectURL(file);
  
      await new Promise((resolve) => {
        image.onload = () => resolve();
      });
  
      const landmarks = await faceLandmarker.detect(image);
  
      faceLandmarker.close();
  
      return { status: true, landmarks };
    } catch (error) {
      console.error("Error during face landmark detection:", error);
      return { status: false, landmarks: null };
    }
  }