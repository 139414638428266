import * as THREE from 'three';

class VitruvianColors {
    constructor(data) {
        this.data = data;
    }

    async colorAverage(){
        const dataColorAverage = this.data.dataServer._extractions.materials.color.average;
        const colorAverage = new THREE.Color(dataColorAverage[0],dataColorAverage[1],dataColorAverage[2]);
        colorAverage.convertLinearToSRGB();
        return colorAverage;
    }

    async run(){return {average:await this.colorAverage()}}
}

export default VitruvianColors;
