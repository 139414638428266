import * as THREE from 'three';
import VitruvianUVs from './uvs'

class VitruvianFace {
  constructor(data){
    this.data=data;
    this.child=null;
  }

  uvs(){
    const uvs=new VitruvianUVs(this.data).run();
    return uvs;
  }

  run(){
    this.child=this.data.mediaprocessing.child;
    this.child.material.map = this.data.mediaprocessing.textureFace;
    const vertices=this.data.mediaprocessing.vertices;

    if (vertices.count > 0) {
      const vitruvianLandmarks=this.data.mediaprocessing.vitruvianLandmarks
      vitruvianLandmarks.forEach((landmark, index) => {
        const currentPosition = new THREE.Vector3().fromBufferAttribute(vertices, index);
        const translation = new THREE.Vector3(landmark.x, landmark.y, landmark.z).sub(currentPosition);
        vertices.setXYZ(index, currentPosition.x + translation.x, currentPosition.y + translation.y, currentPosition.z + translation.z);
      });

      this.uvs()

      this.child.material.color = this.data.mediaprocessing.colors.average/255;
      this.child.material.needsUpdate = true;
      vertices.needsUpdate = true;
    } else {
      console.error("No vertices found.");
    }
  }
}

export default VitruvianFace;
