import VitruvianBody from './processes/body/body';
import VitruvianFace from './processes/body/head/face/face';
import VitruvianHead from './processes/body/head/head';
import VitruvianMaterials from './processes/materials/materials';
import runLandmarksInit from './processes/body/head/face/landmarks-init';

class VitruvianMediaprocessing{
  constructor(data){
    this.data=data;
    this.child=null;
    this.colors=null;
    this.geometry=null;
    this.image=null;
    this.landmraksInit=null;
    this.textureFace=null;
    this.textureColorAverage=null;
    this.uvs=null;
    this.vitruvianLandmarks=null;
    this.vertices=null;
  }

  async vitruvianLandmraksInit () {
    if (this.image){
      this.landmraksInit=await runLandmarksInit(this.image)
      return this.landmraksInit
    }
    else {
      this.landmraksInit={status:false,landmarks:null}
      return this.landmraksInit
    }
  }

  vitruvianBody(){
    const body=new VitruvianBody(this.data).run();
    return body;
  }

  vitruvianFace(){
    const face=new VitruvianFace(this.data).run();
    return face;
  }

  vitruvianHead(){
    const head=new VitruvianHead(this.data).run();
    return head;
  }

  async vitruvianMaterials(){
    const materials=await new VitruvianMaterials(this.data).run();
    this.colors = materials.colors;
    this.textureColorAverage=materials.textures.average;
    this.textureFace=materials.textures.face;
    return materials;
  }
};

export default VitruvianMediaprocessing;
