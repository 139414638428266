import VitruvianColors from './colors';
import VitruvianTextures from './textures';

class VitruvianMaterials {
  constructor(data) {
    this.data = data;
    this.colors = null;
    this.textures = null;
  }

  async run(){
    this.colors=await new VitruvianColors(this.data).run();
    this.data.mediaprocessing.colors=this.colors;
    this.textures=await new VitruvianTextures(this.data).run();
    return {colors:this.colors,textures:this.textures}
  }

};

export default VitruvianMaterials;
