import './info.css';
import '../theme/theme.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Info = () => {
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div>
      <div>
        <button onClick={toggleInfo} style={{ fontSize: '3em' }}>ⓘ</button>
      </div>
      {showInfo && (
        <div style={{ fontSize: '80%', fontWeight: 'bold', textAlign: 'left', marginTop: '10px', padding: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '5px', background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.15), rgba(0, 0, 0, 0.15))' }}>
          <p>
            <span className='disclaimer'>! DISCLAIMER !</span>
            <br/>
            <span className='themed-info-text'>this app is in beta!</span>
            <br/>
            <span className='themed-info-text'>this app takes images with faces and returns 3D models based on the input.</span>
            <br/>
            <span className='themed-info-text'>this app has only one developer.</span>
            <br/>
            <span className='themed-info-text'>not only are there known bugs but there are unknown bugs as well.</span>
            <br/>
            <span className='themed-info-text'>please use at your own discretion. only use images that you feel comfortable with sharing.</span>
            <br/>
            <span className='disclaimer'>! DISCLAIMER !</span>
            <br/>
            <br/>
            <span className='themed-info-text'>using the app is easy but you can see some examples here:</span>
            <br/>
            <a className='links' href="https://www.artstation.com/artwork/xYKXzW" target="_blank" rel="noopener noreferrer">blog</a>
            <br/><br/>
            <span className='themed-info-text'>for comments, questions, please e-mail here:</span>
            <br/>
            <span className='themed-info-text'>phicorp2358@gmail.com </span>
            <br/>
            <br/>
            <span className='themed-info-text'>founder:</span>
            <br/>
            <a className='links' href="https://www.phi-quest.info/" target="_blank" rel="noopener noreferrer">quest</a>
            <br/>
            <br/>
            <Link className='links' to="/changelog">changelog</Link>
            <br/>
            <br/>
            <Link style={{color:'red'}} to="/bugs">bugs</Link>
            <br/>
          </p>
        </div>
      )}
    </div>
  );
};

export default Info;
