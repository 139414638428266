import React, { useState } from 'react';
import './dropdown.css';
import '../../theme/theme.css'

const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="themed-dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <span className='themed-dropdown-title'>{title}</span>
        <span className='themed-dropdown-switch'>{isOpen ? 'v' : '>'}</span>
      </div>
      {isOpen && <div className="dropdown-content">{children}</div>}
    </div>
  );
};

export default Dropdown;
