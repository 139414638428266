import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const GeneratorEditor = ({ data, facemesh }) => {
  const editorRef = useRef();
  const sceneRef = useRef(new THREE.Scene());
  const rendererRef = useRef();
  const cameraRef = useRef();

  useEffect(() => {
    const width = data.generatorEditorData.cropperData.editorWindow.width;
    const height = data.generatorEditorData.cropperData.editorWindow.height;

    if (!rendererRef.current) {
      rendererRef.current = new THREE.WebGLRenderer({ alpha: true });
      rendererRef.current.setSize(width, height);
      rendererRef.current.setClearColor(0x000000, 0);
      editorRef.current.appendChild(rendererRef.current.domElement);
    }

    if (!cameraRef.current) {
      cameraRef.current = new THREE.OrthographicCamera(
        -width / 2, width / 2, height / 2, -height / 2, 0.1, 1000
      );
      cameraRef.current.position.z = 5;
    }

    const animate = () => {
      requestAnimationFrame(animate);
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    animate();

    return () => {
      rendererRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    sceneRef.current.clear();

    if (!facemesh || !facemesh.faceLandmarks || !Array.isArray(facemesh.faceLandmarks.faceLandmarks)) {
      console.error('Invalid facemesh structure:', facemesh);
      return;
    }

    const landmarksArray = facemesh.faceLandmarks.faceLandmarks[0];
    if (!Array.isArray(landmarksArray)) {
      console.error('Expected an array for landmarksArray, got:', landmarksArray);
      return;
    }

    const { width, height } = data.generatorEditorData.cropperData.editorWindow;

    const facemeshGeometry = new THREE.BufferGeometry();
    const vertices = [];

    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;

    landmarksArray.forEach(landmark => {
      if (landmark.x < minX) minX = landmark.x;
      if (landmark.x > maxX) maxX = landmark.x;
      if (landmark.y < minY) minY = landmark.y;
      if (landmark.y > maxY) maxY = landmark.y;
    });

    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;

    const zoomFactor = data.zoom / 100;
    const scaleFactor = 1 / zoomFactor;

    const shiftX = (zoomFactor - 1) * width * 0.5;
    const shiftY = (zoomFactor - 1) * height * 0.5;

    landmarksArray.forEach(landmark => {
      const relativeX = landmark.x - centerX;
      const relativeY = landmark.y - centerY;

      const scaledX = centerX + relativeX * scaleFactor;
      const scaledY = centerY + relativeY * scaleFactor;

      const screenX = scaledX * width + shiftX;
      const screenY = scaledY * height + shiftY;

      vertices.push(
        screenX - width / 2,
        -(screenY - height / 2),
        landmark.z || 0
      );
    });

    facemeshGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    const pointsMaterial = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 5,
      sizeAttenuation: true,
    });

    const facemeshPoints = new THREE.Points(facemeshGeometry, pointsMaterial);
    sceneRef.current.add(facemeshPoints);

    return () => {
      facemeshGeometry.dispose();
      pointsMaterial.dispose();
    };
  }, [facemesh, data.zoom]);

  return (
    <div 
      ref={editorRef} 
      className="generator-editor"
      style={{ 
        top: `${data.generatorEditorData.cropperData.editorWindow.y}px`,
        left: `${data.generatorEditorData.cropperData.editorWindow.x}px`,
        width: `${data.generatorEditorData.cropperData.editorWindow.width}px`,
        height: `${data.generatorEditorData.cropperData.editorWindow.height}px`,
      }}
    />
  );
};

export default GeneratorEditor;
