import './editor.css';
import '../../theme/theme.css';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { Color } from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { OrbitControls } from '@react-three/drei';
import React, { useRef, useState, useCallback } from 'react';
import ModelViewer from './model-viewer';
import SceneWrapper from './scenewrapper';

function Editor({ data }) {
  const cameraRef = useRef();
  const sceneRef = useRef();
  const spotlightColor = new Color(4, 2, 2);
  const [scene, setScene] = useState(null);

  const downloadGLTFFile = useCallback(() => {
    if (!sceneRef.current) return;

    const exporter = new GLTFExporter();

    sceneRef.current.traverse((child) => {
      if (child.isMesh) {
        if (!child.material.color) {
          child.material.color = new THREE.Color(0xffffff);
        }
        if (!child.material.isMeshStandardMaterial) {
          child.material = new THREE.MeshStandardMaterial({
            color: child.material.color || 0xffffff,
            map: child.material.map || null,
            normalMap: child.material.normalMap || null,
            roughness: child.material.roughness !== undefined ? child.material.roughness : 1,
            metalness: child.material.metalness !== undefined ? child.material.metalness : 0,
          });
        }
      }
    });

    exporter.parse(
      sceneRef.current,
      function (gltf) {
        const blob = new Blob([JSON.stringify(gltf, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'vitruvian.gltf';
        a.click();
        URL.revokeObjectURL(url);
      },
      { binary: true }
    );
  }, []);

  function TransparentEnvironment() {return null;}

  function RegularEnvironment() {
    const { Environment } = require('@react-three/drei');
    return (
      <Environment
        background={true}
        blur={data.options.blurValue}
        preset={data.options.environmentPreset}
      />
    );
  }

  return (
    <div className="editor-page-container">
      <div className="editor-container">
        <div className="editor-wrapper">
          <Canvas shadows className="editor-canvas" gl={{ alpha: true }}>
            <SceneWrapper setScene={setScene} sceneRef={sceneRef} />
            {data.options.isEnvironmentTransparent ? <TransparentEnvironment /> : <RegularEnvironment />}
            <ambientLight intensity={data.options.environmentBrightness} />
            <directionalLight castShadow={true} intensity={data.options.environmentBrightness} position={[5, 5, 5]} />
            <spotLight
              color={spotlightColor}
              position={[-50, -50, -100]}
              angle={Math.PI / 4}
              intensity={data.options.environmentBrightness * 0.3}
            />
            <ModelViewer
              cameraRef={cameraRef}
              data={data}
              isWireframe={data.options.isWireframe}
              isTurntableOn={data.options.isTurntableOn}
              isOutfitVisible={data.options.isOutfitVisible}
              sceneRef={sceneRef}
            />
            <OrbitControls
              enablePan={false}
              enableZoom={false}
              ref={cameraRef}
              target={[0, 0, 0]}
              initialPosition={[0, 0, 5]}
            />
          </Canvas>
        </div>
      </div>
      <div className="editor-buttons">
        <button className="editor-download-button" onClick={downloadGLTFFile}>
          Download GLTF File
        </button>
      </div>
    </div>
  );
}

export default Editor;
