import './options.css';
import './dropdown.css';
import Dropdown from './dropdown';
import React from 'react';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';

const Options = ({ options, optionsUpdate, themeMode }) => {
  const handleToggle = (key) => {
    optionsUpdate({
      ...options,
      [key]: !options[key],
    });
  };

  const handleBlurChange = (event, value) => {
    optionsUpdate({
      ...options,
      blurValue: value,
    });
  };

  const handleBrightnessChange = (event, value) => {
    optionsUpdate({
      ...options,
      environmentBrightness: value,
    });
  };

  const handlePresetChange = (event) => {
    optionsUpdate({
      ...options,
      environmentPreset: event.target.value,
    });
  };

  return (
    <div className="options">
      <Dropdown title="Environment">
        <div className='option'>
          <span className='themed-option-text'>Transparency</span>
          <Switch
            onChange={() => handleToggle('isEnvironmentTransparent')}
            checked={options.isEnvironmentTransparent}
          />
        </div>

        <div className='option'>
          <span className='themed-option-text'>Blur</span>
          <Slider
            value={options.blurValue}
            onChange={handleBlurChange}
            min={0}
            max={1}
            step={0.025}
          />
        </div>
        <div className='option'>
          <span className='themed-option-text'>Brightness</span>
          <Slider
            value={options.environmentBrightness}
            onChange={handleBrightnessChange}
            min={0}
            max={1}
            step={0.025}
          />
        </div>
        <div className='option'>
          <span className='themed-option-text'>HDRI</span>
          <select className='dropdown-header' id="preset" onChange={handlePresetChange} defaultValue={options.environmentPreset}>
            <option value="city">City</option>
            <option value="forest">Forest</option>
            <option value="sunset">Sunset</option>
            <option value="dawn">Dawn</option>
            <option value="night">Night</option>
            <option value="warehouse">Warehouse</option>
            <option value="apartment">Apartment</option>
            <option value="studio">Studio</option>
            <option value="lobby">Lobby</option>
            <option value="park">Park</option>
          </select>
        </div>
      </Dropdown>
      <div className='option'>
        <span className='themed-option-text'>Outfit</span>
        <Switch
          onChange={() => handleToggle('isOutfitVisible')}
          checked={options.isOutfitVisible}
        />
      </div>
      <div className='option'>
        <span className='themed-option-text'>Turntable</span>
        <Switch
          onChange={() => handleToggle('isTurntableOn')}
          checked={options.isTurntableOn}
        />
      </div>
      <div className='option'>
        <span className='themed-option-text'>Wireframe</span>
        <Switch
          onChange={() => handleToggle('isWireframe')}
          checked={options.isWireframe}
        />
      </div>
    </div>
  );
};

export default Options;
