import './generate.css';
import '../../../theme/theme.css';
import ImgProcessing from '../processing/img';
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';

const Generate = ({ data, landmarks, onUUIDGenerated, selectedFile, wait }) => {
  const [uuid, setUuid] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (wait) {
      setLoading(false);
    }
  }, [wait]);

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        setLoading(true);
        const generatedUuid = uuidv4();
        setUuid(generatedUuid);

        const dataDest = `dev/${data.currentDate}/${generatedUuid}/data_client.json`;

        let dataClient = {};

        dataClient.style = {
          extractions: {
            head: {
              face: {
                landmarks: landmarks,
              },
            },
          },
        };

        let processedFile = selectedFile;

        const jpgFile = await ImgProcessing(data).convertToJpg(processedFile);

        await Promise.all([
          Storage.put(
            `dev/${data.currentDate}/${generatedUuid}/img.jpg`,
            jpgFile,
            { level: 'public', contentType: jpgFile.type, region: 'us-west-2' }
          ),
          Storage.put(
            dataDest,
            JSON.stringify(dataClient),
            { level: 'public', contentType: 'application/json', region: 'us-west-2' }
          )
        ]);

        console.log('File uploaded successfully!');
        onUUIDGenerated(generatedUuid);
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      }
    }
  };

  const isDisabled = Boolean(loading || wait);

  return (
    <div>
      <button 
        className={`generate-button ${loading ? 'loading' : ''}`} 
        onClick={handleUpload} 
        disabled={isDisabled}
      >
        {loading ? 'Generating...' : 'Generate'}
      </button>
      <div className="themed-generate-container">
        {uuid && <p>ID: {uuid}</p>}
      </div>
    </div>
  );
};

export default Generate;
