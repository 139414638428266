class Data {
  constructor(currentDate, imageSrc, modelUuid,setDataServer, setError, setModelUrl, setShowEditor) {
    this.currentDate = currentDate;
    this.imageSrc = imageSrc;
    this.modelUuid = modelUuid;
    this.setDataServer = setDataServer;
    this.setError = setError;
    this.setModelUrl = setModelUrl;
    this.setShowEditor = setShowEditor;
  }

  vitruvianDataServer = async() => {
    const { imageSrc, modelUuid, currentDate ,setDataServer, setModelUrl, setShowEditor, setError } = this;
    if (imageSrc && modelUuid) {
      const uuid = modelUuid.split('.')[0];
      const dataServer = `https://phi-vitruvian2358.s3.us-west-2.amazonaws.com/public/dev/${currentDate}/${uuid}/data.json`;

      try {
        const response = await fetch(dataServer);
        if (response.ok) {
          const data = await response.json();
          setDataServer(data);
          setModelUrl(`/vitruvian_${data._extractions.gender.identity[0]}.glb`);
          setShowEditor(true);
          return data;
        } else {
          setError('The GLB data is not available yet. Please wait.');
          setTimeout(this.vitruvianData, 1500);
        }
      } catch (error) {
        console.log('An error occurred while checking data availability:', error);
        setError('An error occurred while checking data availability. Retrying...');
        setTimeout(this.vitruvianData, 1500);
      }
    }
  }  
}


export default Data;
