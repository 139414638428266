import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const gltfLoader = new GLTFLoader();

const outfitLoad = async (data) => {
  const outfitUrl = `/${data.dataServer._extractions.gender.identity[0]}_tux.glb`;
  const gltf = await gltfLoader.loadAsync(outfitUrl);
  const model = gltf.scene;
  return model;
};

const hairLoad=async(data)=>{
  const hairUrl = `/hair_${data.dataServer._extractions.gender.identity}/hair.glb`;
  const gltf = await gltfLoader.loadAsync(hairUrl);
  const model = gltf.scene;
  return model;
}

const vitruvianLoad = async (data) => {
  try {
    const gltf = await gltfLoader.loadAsync(data.modelUrl);
    const model = gltf.scene;

    await data.mediaprocessing.vitruvianMaterials();

    model.traverse((child) => {
      if (child.isMesh) {
        data.mediaprocessing.child=child;
        if (child.material.name === 'body') {
          data.mediaprocessing.vitruvianBody();
        }
        if(child.material.name==='head'){
          const geometry = child.geometry;
          data.mediaprocessing.vertices=geometry.attributes.position;
          data.mediaprocessing.vitruvianHead();
        }
        if(child.material.name==='face'){
          const geometry = child.geometry;
          data.mediaprocessing.uvs=geometry.attributes.uv;
          data.mediaprocessing.vertices=geometry.attributes.position;
          data.mediaprocessing.vitruvianFace();
        }
      }
    });
    return model;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { outfitLoad,hairLoad,vitruvianLoad };
