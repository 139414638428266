import '../generator.css';
import '../../../theme/theme.css'
import React, { useState } from 'react';

const DragDrop = ({ onImageDrop }) => {
  const [dragging, setDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    onImageDrop(file);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    onImageDrop(file);
  };

  const fileInputRef = React.useRef(null);

  return (
    <div className="dragdrop-container"> {}
      <div
        className={`drag-drop ${dragging ? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleBrowseClick}
      >
        <span className="themed-drag-drop-text">Drag & Drop / Click to Browse</span>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleFileInputChange}
        />
      </div>
    </div>
  );
};

export default DragDrop;
