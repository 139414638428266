const ImgProcessing = (data) => {
    const convertToJpg = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
              const jpgFile = new File([blob], 'converted-image.jpg', { type: 'image/jpeg' });
              resolve(jpgFile);
            }, 'image/jpeg');
          };
          img.src = event.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };
  
    const run = async (file) => {
      const jpgFile = await convertToJpg(file);
      data.mediaprocessing.image = jpgFile;
      const { status, landmarks } = await data.mediaprocessing.vitruvianLandmraksInit();
      const faceDetected = status && landmarks && landmarks.faceLandmarks && landmarks.faceLandmarks.length > 0;
      const faceLandmarks = landmarks;
      return { jpgFile, faceDetected, faceLandmarks };
    };
  
    return { run, convertToJpg };
  };
  
  export default ImgProcessing;
  