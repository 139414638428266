import { ThemeProvider, createTheme } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import React from 'react';
import './zoom.css'

const Zoom = ({ themeMode, zoomLevel, handleZoomChange }) => {
  const theme = createTheme({
    palette: {
      mode: themeMode,
      ...(themeMode === 'dark' ? {
        background: {
          default: '#000000',
          paper: '#333333',
        },
        text: {
          primary: '#ffffff',
        },
      } : {
        background: {
          default: '#ffffff',
          paper: '#f5f5f5',
        },
        text: {
          primary: '#000000',
        },
      }),
    },
    components: {
      MuiSlider: {
        styleOverrides: {
          rail: {
            color: themeMode === 'dark' ? '#444444' : '#cccccc',
          },
          track: {
            height: 4,
            background: themeMode === 'dark' ? '#cccccc' : '#333333',
          },
          thumb: {
            color: themeMode === 'dark' ? '#ffffff' : '#000000',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Slider
        className='zoom'
        min={50}
        max={200}
        value={zoomLevel}
        onChange={handleZoomChange}
        aria-label="Zoom Slider"
        valueLabelDisplay="auto"
      />
    </ThemeProvider>
  );
};

export default Zoom;
