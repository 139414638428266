import './generator.css';
import '../../theme/theme.css';
import DragDrop from './dragdrop/drag-drop';
import Generate from './generate/generate';
import GeneratorCropper from './cropper/cropper';
import GeneratorEditor from './editor/editor';
import generateTextToImage from '../mediaprocessing/diffusion/text-to-img';
import ImgProcessing from './processing/img';
import React, { useState, useEffect, useRef } from 'react';

const Generator = ({ data,onImageSrcChange, onUUIDGenerated, wait }) => {
  const cropperRef = useRef(null);
  const imageWrapperRef = useRef(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [faceDetected, setFaceDetected] = useState(false);
  const [faceLandmarks, setFaceLandmarks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPromptText] = useState('');
  const [uploadImage, setUploadImage] = useState(null);

  const calculateOnScreenCroppedArea = () => {
    if (!cropperRef.current) return {};

    const cropperElement = cropperRef.current.querySelector('.reactEasyCrop_CropArea');
    const cropperRect = cropperElement.getBoundingClientRect();
    const imageWrapperRect = imageWrapperRef.current.getBoundingClientRect();

    const centerX = cropperRect.width / 2;
    const centerY = cropperRect.height / 2;

    const offsetX = cropperRect.left - imageWrapperRect.left;
    const offsetY = cropperRect.top - imageWrapperRect.top;

    const zoomFactor=data.zoom/100;

    return {
      x: centerX - cropperRect.width / 2 + offsetX/zoomFactor,
      y: centerY - cropperRect.height / 2 + offsetY/zoomFactor,
      width: cropperRect.width/zoomFactor,
      height: cropperRect.height/zoomFactor,
    };
  };

  const onScreenCroppedArea = calculateOnScreenCroppedArea();

  data.generatorEditorData.cropperData.editorWindow={
    x: onScreenCroppedArea.x,
    y: onScreenCroppedArea.y,
    width: onScreenCroppedArea.width,
    height: onScreenCroppedArea.height,  
  };

  const handleFileChange = async (file) => {
    setLoading(true);
  
    const url = URL.createObjectURL(file);
    const img = new Image();
  
    img.onload = () => {
      onImageSrcChange({
        img
      });
    };
  
    img.src = url;
    data.generatorEditorData.originalImageData.src = img;

    const { jpgFile, faceDetected, faceLandmarks } = await ImgProcessing(data).run(file);
    setFaceDetected(faceDetected);
    setFaceLandmarks(faceLandmarks);
    setCurrentImage(jpgFile);
    setLoading(false);
  };

  const handleGenerateTextToImage = async () => {
    setLoading(true);
    const generatedBlob = await generateTextToImage(prompt);
    const jpgFile = await ImgProcessing(data).convertToJpg(generatedBlob);
    setCurrentImage(jpgFile);
    await ImgProcessing(data).run(jpgFile);
    setLoading(false);
  };

  const handleCropComplete = (croppedAreaPixels, faceDetected, faceLandmarks,originalFile,uploadFile) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setFaceDetected(faceDetected);
    setFaceLandmarks(faceLandmarks);
    setUploadImage(uploadFile);
    setCurrentImage(originalFile);
  };

  useEffect(() => {
    if (imageWrapperRef.current && currentImage) {
      imageWrapperRef.current.scrollTop =
        imageWrapperRef.current.scrollHeight / 2 - imageWrapperRef.current.clientHeight / 2;
    }
  }, [currentImage]);

  return (
    <div className="generator-container">
      <div className="input-container">
        <div className="diffusion-container">
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPromptText(e.target.value)}
            placeholder="Enter prompt text"
          />
          <button
            onClick={handleGenerateTextToImage}
            disabled={loading || prompt.trim() === ""}
          >
            Generate
          </button>
        </div>
        <div>or</div>
        <div>
          <DragDrop data={data} onImageDrop={handleFileChange} />
        </div>
      </div>

      {currentImage && (
        <div className="image-preview">
          <div className="image-wrapper" ref={imageWrapperRef}>
            <img
              alt="Current Image"
              className="preview-image"
              imgsrc={typeof currentImage === 'string' ? currentImage : URL.createObjectURL(currentImage)}
            />
            {currentImage && (
              <div className="cropper" ref={cropperRef} >
                <GeneratorCropper
                  data={data}
                  imgsrc={typeof currentImage === 'string' ? currentImage : URL.createObjectURL(currentImage)}
                  onCropComplete={handleCropComplete}
                />
                {croppedAreaPixels && (
                  <GeneratorEditor
                    data={data}
                    facemesh={{ faceLandmarks }}
                  />
                )}
                {croppedAreaPixels && (
                  <div
                    className="editor-window"
                    style={{
                      top: `${data.generatorEditorData.cropperData.editorWindow.y}px`,
                      left: `${data.generatorEditorData.cropperData.editorWindow.x}px`,
                      width: `${data.generatorEditorData.cropperData.editorWindow.width}px`,
                      height: `${data.generatorEditorData.cropperData.editorWindow.height}px`,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="themed-generator-confirmation">
        {currentImage ? (
          faceDetected ? (
            <p>Face Detected!</p>
          ) : (
            <p>No Face Detected!</p>
          )
        ) : null}
      </div>

      {faceDetected ? (
        <div className="generate-wrapper">
          <Generate
            data={data}
            landmarks={faceLandmarks}
            onUUIDGenerated={onUUIDGenerated}
            selectedFile={uploadImage}
            wait={wait}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Generator;
